<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="sop.plantName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="단위공정" :value="sop.processName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="작업형태" :value="sop.workKindName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="작업유형" :value="sop.workTypeName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="작업순서"
        :isTitle="true"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :gridHeightAuto="true"
        :hideBottom="true"
        :columns="grid.columns"
        :data="sop.sopSteps"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props }">
          <template>
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail(props.row, props.pageIndex)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.jobStepName"></div>
          <div class="col-4"><div class="mini-dailog-title">순번</div></div>
          <div class="col-8" v-text="selectedRow.jobStepNo"></div>
          <div class="col-4"><div class="mini-dailog-title">작업순서 설명</div></div>
          <div class="col-8" v-text="selectedRow.jobStepDesc"></div>
          <div class="col-4"><div class="mini-dailog-title">안전작업방법</div></div>
          <div class="col-8" v-text="selectedRow.saftyWorkMethod"></div>
          <div class="col-4"><div class="mini-dailog-title">안전보호구</div></div>
          <div class="col-8" v-text="selectedRow.saftyShied"></div>
          <div class="col-4"><div class="mini-dailog-title">사용공구</div></div>
          <div class="col-8" v-text="selectedRow.useTool"></div>
          <div class="col-4"><div class="mini-dailog-title">주의사항</div></div>
          <div class="col-8" v-text="selectedRow.caution"></div>
          <div class="col-4"><div class="mini-dailog-title">사용여부</div></div>
          <div class="col-8" v-text="selectedRow.useFlag"></div>
          <div class="col-4"><div class="mini-dailog-title">도해(사진/그림)</div></div>
          <!-- <div class="col-8">
            <q-btn 
              unelevated round dense
              size="10px"
              color="primary" 
              icon="wallpaper"
              @click.stop="openPicture(selectedRow)" />
          </div> -->
          <div class="col-12">
            <c-upload-picture
              height="300px"
              :attachInfo="attachInfo"
              :editable="false">
            </c-upload-picture>
          </div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
      }),
    },
  },
  data() {
    return {
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      grid: {
        columns: [
          {
            name: 'jobStepNo',
            field: 'jobStepNo',
            label: '순번',
            style: 'width:50px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업순서',
            style: 'width:300px',
            type: 'link',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'jobStepDesc',
          //   field: 'jobStepDesc',
          //   label: '작업단계 설명',
          //   align: 'left',
          //   type: 'textarea',
          //   style: 'width:20%',
          //   sortable: false,
          // },
          // {
          //   name: 'saftyWorkMethod',
          //   field: 'saftyWorkMethod',
          //   label: '안전작업방법',
          //   align: 'left',
          //   type: 'textarea',
          //   style: 'width:20%',
          //   sortable: false,
          // },
          // {
          //   name: 'saftyShied',
          //   field: 'saftyShied',
          //   label: '안전보호구',
          //   align: 'left',
          //   type: 'textarea',
          //   style: 'width:15%',
          //   sortable: false,
          // },
          // {
          //   name: 'useTool',
          //   field: 'useTool',
          //   label: '사용공구',
          //   align: 'left',
          //   type: 'textarea',
          //   style: 'width:15%',
          //   sortable: false,
          // },
          // {
          //   name: 'caution',
          //   field: 'caution',
          //   label: '주의사항',
          //   align: 'left',
          //   type: 'textarea',
          //   style: 'width:15%',
          //   sortable: false,
          // },
          // {
          //   name: 'useFlag',
          //   field: 'useFlag',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:70px',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
        ],
        data: [],
      },
      sop: {
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        sopMocId: '',
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
        sopStepHazards: [], // 작업단계 (w/h. 유해위험요인)
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOP_STEP_PHOTO',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.sop.job.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmSopId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmSopId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.sop = _result.data;
        },);
      }
    },
    linkClick(row) {
      this.popupOptions.title = '유해위험요인 상세'; // 유해위험요인 상세
      this.popupOptions.param = {
        mdmSopId: this.popupParam.mdmSopId,
        jobStepId: row ? row.jobStepId : '',
        jobStepName: row ? row.jobStepName : '',
        jobStepNo: row ? row.jobStepNo : '',
      };
      this.popupOptions.target = () => import(`${'./sopDetailRiskHazard.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
      this.$set(this.attachInfo, 'taskKey', _row.jobStepId)
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    // openPicture(row) {
    //   this.popupOptions.title = '도해(사진/그림) 사진';
    //   this.popupOptions.param = {
    //     jobStepId: row.jobStepId,
    //   };
    //   this.popupOptions.target = () => import(`${'./jobStepPicture.vue'}`);
    //   this.popupOptions.width = '99%';
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePicturePopup;
    // },
    // closePicturePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    // },
  }
};
</script>