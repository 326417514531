var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-label-text", {
                      attrs: { title: "사업장", value: _vm.sop.plantName },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-label-text", {
                      attrs: { title: "단위공정", value: _vm.sop.processName },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-label-text", {
                      attrs: { title: "작업형태", value: _vm.sop.workKindName },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-label-text", {
                      attrs: { title: "작업유형", value: _vm.sop.workTypeName },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "작업순서",
              isTitle: true,
              columnSetting: false,
              usePaging: false,
              filtering: false,
              gridHeightAuto: true,
              hideBottom: true,
              columns: _vm.grid.columns,
              data: _vm.sop.sopSteps,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      _c("q-btn", {
                        attrs: {
                          flat: "",
                          size: "12px",
                          color: "orange",
                          icon: "chevron_right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClickDetail(
                              props.row,
                              props.pageIndex
                            )
                          },
                        },
                      }),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.jobStepName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("순번"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.jobStepNo) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("작업순서 설명"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.jobStepDesc),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("안전작업방법"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.saftyWorkMethod),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("안전보호구"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.saftyShied) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("사용공구"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.useTool) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("주의사항"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.caution) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("사용여부"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: { textContent: _vm._s(_vm.selectedRow.useFlag) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("도해(사진/그림)"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload-picture", {
                      attrs: {
                        height: "300px",
                        attachInfo: _vm.attachInfo,
                        editable: false,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }